import { useEffect } from "react"

import { format, parseISO, subMinutes } from "date-fns"

import { driverRotaPublishedToDate } from "./date-utils"
import { useGlobalDispatch, useGlobalState } from "./state-utils"

export function useRotaVisibilityDate(asString = true) {
  const { fetchers } = useGlobalState()
  const dispatch = useGlobalDispatch()
  const { data: rotaVisibilityDate, lastFetched } = fetchers.rotaVisibilityDate
  const now = new Date()

  const hoursSinceLastFetch = lastFetched
    ? (now.getTime() - lastFetched.getTime()) / (1000 * 60 * 60)
    : 0

  useEffect(() => {
    if (!rotaVisibilityDate || hoursSinceLastFetch > 1) {
      dispatch({ shouldFetch: "rotaVisibilityDate" })
    }
  }, [])

  const result =
    rotaVisibilityDate && rotaVisibilityDate.date
      ? parseISO(rotaVisibilityDate.date)
      : driverRotaPublishedToDate()
  return asString ? format(subMinutes(result, 1), "yyyy-MM-dd") : result
}
