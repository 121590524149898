import React from "react"

import { AdminLayout } from "components/layout-custom"
import RotaAnalysis from "components/rota/rota-analysis-view"

const Page = () => (
  <AdminLayout title="Rota Analysis" padded={false}>
    <RotaAnalysis />
  </AdminLayout>
)

export default Page
