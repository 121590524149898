import React from "react"

import styled from "styled-components"

const TableWrapper = styled.div`
  flex: 1 1 auto;
  overflow-x: scroll;
  overflow-y: scroll;
  height: 0px;
`

const Table = styled.table`
  background: ${(props) => props.theme.orbit.backgroundTableEven};
  color: ${(props) => props.theme.orbit.colorTextPrimary};
  height: 100%;
`

const TableHead = styled.thead``

const TableBody = styled.tbody``

const TableRow = styled.tr``

const TableCell = styled.td`
  vertical-align: middle;
  box-shadow: ${(props) =>
    `inset -1px -1px 0  ${props.theme.orbit.borderColorTableCell}`};
  padding-top: 0px;
  padding-left: 0px;
`

interface StickyRowAndColumnCellProps {
  rowOffset?: number
}

const StickyRowAndColumnCell = styled(TableCell)<StickyRowAndColumnCellProps>`
  position: sticky;
  top: ${(props) => (props.rowOffset != null ? props.rowOffset * 66 : 0)}px;
  left: 0px;
  z-index: 3;
  background: ${(props) => props.theme.orbit.backgroundTableEven};
  font-weight: bold;
`

interface StickyRowCellProps {
  heading?: boolean
  highlighted?: boolean
  rowOffset?: number
}

const StickyRowCell = styled(TableCell)<StickyRowCellProps>`
  position: sticky;
  top: ${(props) => (props.rowOffset != null ? props.rowOffset * 66 : 0)}px;
  z-index: 1;
  background: ${(props) => {
    if (props.highlighted) {
      return props.theme.orbit.backgroundTableHover
    } else {
      return props.theme.orbit.backgroundTableEven
    }
  }};
  font-weight: ${(props) => (props.heading ? "bold" : "normal")};
`

const StickyColumnCell = styled(TableCell)`
  position: sticky;
  left: 0px;
  z-index: 2;
  background: ${(props) => props.theme.orbit.backgroundTableEven};
  font-weight: bold;
`

interface CellContentProps {
  clickable?: boolean
}

export const CellContent = styled.div<CellContentProps>`
  min-height: 100%;
  padding: 10px;
  width: 132px;
  height: 65px;
  align-items: center;
  display: flex;
  line-height: 1.2;
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
`

type TableCell = {
  content: JSX.Element | string
  highlighted?: boolean
}

type EmberTableRow = {
  leftCell: TableCell
  row: TableCell[]
}

const EmberTableHeader = ({ leftCell, row }: EmberTableRow) => {
  return (
    <TableRow>
      <StickyRowAndColumnCell>
        <CellContent>{leftCell?.content}</CellContent>
      </StickyRowAndColumnCell>
      {row?.map((headerCell, i) => {
        return (
          <StickyRowCell key={i} highlighted={headerCell.highlighted} heading>
            <CellContent>{headerCell.content}</CellContent>
          </StickyRowCell>
        )
      })}
    </TableRow>
  )
}

const EmberTableRow = ({ leftCell, row }: EmberTableRow) => {
  return (
    <TableRow>
      <StickyColumnCell>
        <CellContent>{leftCell?.content}</CellContent>
      </StickyColumnCell>
      {row?.map((headerCell, i) => {
        return (
          <TableCell key={i}>
            <CellContent>{headerCell?.content}</CellContent>
          </TableCell>
        )
      })}
    </TableRow>
  )
}

interface EmberTableProps {
  header: EmberTableRow
  content: EmberTableRow[]
}

export const EmberTable = ({ header, content }: EmberTableProps) => {
  return (
    <TableWrapper>
      <Table>
        <TableHead>
          <EmberTableHeader leftCell={header.leftCell} row={header.row} />
        </TableHead>
        <TableBody>
          {content.map((row, i) => {
            return (
              <EmberTableRow leftCell={row.leftCell} row={row.row} key={i} />
            )
          })}
        </TableBody>
      </Table>
    </TableWrapper>
  )
}
