// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import { format, isBefore, isValid, subHours } from "date-fns"

import { Button, Popover, Stack } from "@kiwicom/orbit-components"
import {
  AlertCircle,
  CheckCircle,
  Reload,
} from "@kiwicom/orbit-components/icons"
import Table, {
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
} from "@kiwicom/orbit-components/lib/Table"
import Tabs, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from "@kiwicom/orbit-components/lib/Tabs"

import {
  DateRange,
  EmberDateRangePicker,
} from "components/generic/date-time/ember-date-range-picker"
import { CellContent, EmberTable } from "components/generic/ember-table"
import NavBarLink from "components/generic/navigation/nav-bar-link"
import { TertiaryNavBarWithLeftMenu } from "components/nav-bar-tertiary"

import { formatAsShortestDate, getDefaultTimezone } from "utils/date-utils"
import { fetchFromAPIBase } from "utils/fetch-utils"
import { useRotaVisibilityDate } from "utils/rota-visibility-utils"
import {
  updateQueryParam,
  updateStateFromUrl,
  useQueryString,
} from "utils/url-utils"

type WeeklyCost = {
  start: string
  end: string

  contractedHours: number
  utilization: number
  assignedHours: number
  overtimeWorkHours: number
  workEquivalentHours: number
  longestRestHours: number

  cost: number
  dailyRestCost: number
  longestRestCost: number
  transitionsCost: number
  utilizationCost: number
  offWindowCost: number
  continuousWorkCost: number
  preferenceCost: number
}

interface CostDetailsProps {
  week: WeeklyCost
}

const DetailsRow = ({
  label,
  value,
}: {
  label: string
  value: string | number
}) => (
  <TableRow>
    <TableCell>{label}</TableCell>
    <TableCell>{value}</TableCell>
  </TableRow>
)

const CostDetails = ({ week }: CostDetailsProps) => {
  return (
    <Tabs>
      <TabList>
        <Tab>Cost</Tab>
        <Tab>Details</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Table>
            <TableBody>
              <DetailsRow label="Assignment" value={week.utilizationCost} />
              <DetailsRow label="Off Window" value={week.offWindowCost} />
              <DetailsRow label="Daily Rest" value={week.dailyRestCost} />
              <DetailsRow label="Longest Rest" value={week.longestRestCost} />
              <DetailsRow label="Transitions" value={week.transitionsCost} />
              <DetailsRow label="Preference" value={week.preferenceCost} />
              <DetailsRow
                label="Continuous Work"
                value={week.continuousWorkCost}
              />
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell>Total Cost</TableCell>
                <TableCell>{week.cost}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TabPanel>
        <TabPanel>
          <Table>
            <TableBody>
              <DetailsRow
                label="Contracted Hours"
                value={week.contractedHours}
              />
              <DetailsRow label="Assigned Hours" value={week.assignedHours} />
              <DetailsRow
                label="Leave Hours"
                value={week.workEquivalentHours}
              />
              <DetailsRow
                label="Overtime Work Hours"
                value={week.overtimeWorkHours}
              />
              <DetailsRow
                label="Longest Rest Hours"
                value={week.longestRestHours}
              />
            </TableBody>
          </Table>
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

type CostVariable = {
  label: string
  name: string
}

const costVariables: CostVariable[] = [
  {
    label: "Total Cost",
    name: "cost",
  },
  {
    label: "Daily Rest Cost",
    name: "dailyRestCost",
  },
  {
    label: "Longest Rest Cost",
    name: "longestRestCost",
  },
  {
    label: "Transitions Cost",
    name: "transitionsCost",
  },
  {
    label: "Assigned Hours Cost",
    name: "utilizationCost",
  },
  {
    label: "Contracted Hours",
    name: "contractedHours",
  },
  {
    label: "Assigned Hours",
    name: "assignedHours",
  },
  {
    label: "Utilization",
    name: "utilization",
  },
  {
    label: "Leave Hours",
    name: "workEquivalentHours",
  },
  {
    label: "Overtime Work Hours",
    name: "overtimeWorkHours",
  },
  {
    label: "Longest Rest Hours",
    name: "longestRestHours",
  },
  {
    label: "Preference Cost",
    name: "preferenceCost",
  },
  {
    label: "Off Window Cost",
    name: "offWindowCost",
  },
  {
    label: "Continuous Work Cost",
    name: "continuousWorkCost",
  },
]

const totalProperties = [
  "cost",
  "utilization",
  "assignedHours",
  "overtimeWorkHours",
  "workEquivalentHours",
  "dailyRestCost",
  "contractedHours",
  "longestRestCost",
  "preferenceCost",
  "transitionsCost",
  "utilizationCost",
  "offWindowCost",
  "continuousWorkCost",
]

const totalObject = {
  cost: 0,
  utilization: 0,
  assignedHours: 0,
  overtimeWorkHours: 0,
  workEquivalentHours: 0,
  longestRestHours: 0,
  dailyRestCost: 0,
  contractedHours: 0,
  longestRestCost: 0,
  preferenceCost: 0,
  transitionsCost: 0,
  utilizationCost: 0,
  offWindowCost: 0,
  continuousWorkCost: 0,
}

const RotaAnalysis = () => {
  const rotaVisibilityDate = useRotaVisibilityDate()
  const initialDateFrom = subHours(new Date(), 6)
  const initialDateTo = new Date(rotaVisibilityDate)
  const [drivers, setDrivers] = useState<any>()
  const [dateRange, setDateRange] = useState<DateRange>({
    start: initialDateFrom,
    end: initialDateTo,
  })
  const [useShadowAssignment, setUseShadowAssignment] = useState(false)
  const [presentedVariable, setPresentedVariable] = useState(costVariables[0])
  const [refreshCounter, setRefreshCounter] = useState(0)
  const [loading, setLoading] = useState(false)

  const { queryString } = useQueryString()
  const [loadedQueryString, setLoadedQueryString] = useState(false)

  const setDateRangeFromQueryString = (range: string) => {
    const rangeString = range.split("_")
    if (rangeString.length != 2) {
      updateQueryParam(queryString, { tag: "range", value: null })
      return
    }
    const start = new Date(rangeString[0])
    const end = new Date(rangeString[1])
    if (!isValid(start) || !isValid(end) || isBefore(end, start)) {
      updateQueryParam(queryString, { tag: "range", value: null })
      return
    }
    setDateRange({ start: start, end: end })
  }
  useEffect(() => {
    if (queryString && !loadedQueryString) {
      setLoadedQueryString(true)
      updateStateFromUrl(queryString, urlParams)
    }
  }, [queryString])

  const urlParams = [
    {
      tag: "range",
      setState: setDateRangeFromQueryString,
    },
  ]

  useEffect(() => {
    setLoading(true)
    fetchFromAPIBase({
      path: `/v1/staff/rota/cost/?start=${format(
        dateRange.start,
        "yyyy-MM-dd"
      )}&end=${format(
        dateRange.end,
        "yyyy-MM-dd"
      )}&use_shadow_assignment=${useShadowAssignment}`,
    }).subscribe((response) => {
      if (response && !response.error) {
        const total = {
          driver: { name: "Total" },
          weeks: response[0].weeks.map((week) => ({
            ...totalObject,
            start: week.start,
            end: week.end,
          })),
        }
        response.forEach((driver) => {
          driver.weeks.forEach((week, i) => {
            totalProperties.forEach((key) => {
              total.weeks[i][key] =
                Math.round(100 * (total.weeks[i][key] + week[key])) / 100
            })
          })
        })
        response.unshift(total)
        setDrivers(response)
        setLoading(false)
      }
    })
  }, [dateRange, refreshCounter, useShadowAssignment])

  const handleSetDateRange = (dateRange: DateRange) => {
    setDateRange(dateRange)
    updateQueryParam(queryString, {
      tag: "range",
      value: `${format(dateRange.start, "yyyy-MM-dd")}_${format(
        dateRange.end,
        "yyyy-MM-dd"
      )}`,
    })
  }

  const doneLoading = drivers && drivers[0] && drivers[0].weeks

  const header = doneLoading && {
    leftCell: { content: "Driver" },
    row: drivers[0].weeks
      .map((week) => ({
        content: formatAsShortestDate(
          new Date(week.start),
          getDefaultTimezone()
        ),
        highlighted:
          new Date(week.start) < new Date() && new Date(week.end) > new Date(),
      }))
      .concat({ content: "Total" }),
  }

  const presentVariable = (value) => Number(value.toFixed(2))

  const tableContent =
    doneLoading &&
    drivers.map((driver) => ({
      leftCell: { content: driver.driver.name },
      row: driver.weeks
        .map((week) => ({
          content: (
            <Popover content={<CostDetails week={week} />}>
              <CellContent clickable>
                {presentVariable(week[presentedVariable.name])}
              </CellContent>
            </Popover>
          ),
        }))
        .concat({
          content: (
            <CellContent>
              {presentVariable(
                driver.weeks
                  .map((week) => week[presentedVariable.name])
                  .reduce((a, b) => a + b, 0)
              )}
            </CellContent>
          ),
        }),
    }))

  return (
    <>
      <TertiaryNavBarWithLeftMenu
        menuLabel={presentedVariable.label}
        rightContent={
          <Stack
            direction="row"
            justify="start"
            spacing="XXSmall"
            largeMobile={{ spacing: "small" }}
            align="center"
          >
            <Button
              type={"secondary"}
              onClick={() => {
                setRefreshCounter(refreshCounter + 1)
              }}
              iconLeft={<Reload />}
              disabled={loading}
              loading={loading}
            />
            <Button
              type={useShadowAssignment ? "critical" : "secondary"}
              onClick={() => {
                setUseShadowAssignment(!useShadowAssignment)
              }}
              iconLeft={useShadowAssignment ? <AlertCircle /> : <CheckCircle />}
            />
            <EmberDateRangePicker
              range={dateRange}
              setRange={handleSetDateRange}
              showDay={false}
              showTwoMonths
              shrinkOnMobile
            />
          </Stack>
        }
      >
        {costVariables.map((variable) => (
          <NavBarLink
            to={() => setPresentedVariable(variable)}
            key={variable.name}
          >
            {variable.label}
          </NavBarLink>
        ))}
      </TertiaryNavBarWithLeftMenu>

      {doneLoading && <EmberTable header={header} content={tableContent} />}
    </>
  )
}

export default RotaAnalysis
